<template>
 <mdb-container>

   <mdb-navbar style="background-color:#78B833">
        <span class="navbar-text white-text">
         <img src="https://www.werner-partyservice.de/wp-content/uploads/2021/03/header-1.png" class="img-thumbnail"
          style="width: 200px">
        </span>
    </mdb-navbar>
  


    <div id="app">

      <GerichtAuswahl v-for="(gericht, index) in gerichte" 
       :index="index" 
       :key="index" 
       :anzahlGericht="parseFloat(gericht.anzahl)"
       :kostenGericht="parseFloat(gericht.kosten)"
        @changedKosten="onChangedChildKosten"
        @changedAnzahl="onChangedChildAnzahl"
        @delete="gerichtLoeschen"></GerichtAuswahl>

    
      <mdb-row>
         <mdb-col col="sm-12">
             <div class="text-left"> <mdb-btn color="green-hs" @click.native="gerichtHinzufuegen"><mdb-icon icon="magic" class="mr-1"/> Gericht Hinzufügen</mdb-btn> </div>
             <hr>
              <p class="h4 text-right mr-4"> Gesamt: {{gesamtBestellung}} €</p> 
          </mdb-col> 
      </mdb-row>
      
    </div>

  </mdb-container>
</template>

<script>
import {mdbContainer, mdbBtn, mdbIcon,mdbRow, mdbCol, mdbNavbar} from 'mdbvue';
import GerichtAuswahl from './components/GerichtAuswahl.vue';

export default {
  name: 'App',
  components: {
    mdbContainer,
    mdbBtn,
    mdbIcon,
    mdbRow,
    mdbCol,
    mdbNavbar,
    GerichtAuswahl
  }, 
  methods: {
    onChangedChildAnzahl (index, anzahl) {
      this.gerichte[index].anzahl = anzahl;
    },
    onChangedChildKosten (index, kosten) {
      this.gerichte[index].kosten = kosten;
    },
    gerichtHinzufuegen() {
      this.gerichte.push ({
        anzahl: 1,
        kosten: 0
      })
    },
      gerichtLoeschen(gerichtID) {
         this.gerichte.splice(gerichtID, 1);
      }
  },
  data: () => ({
    gerichte:[
      {anzahl:25.0, kosten:0.0},
      {anzahl:25.0, kosten:0.0}
    ] 
  }),
  computed: {
      gesamtBestellung() {
       return (this.gerichte.reduce((acc, item) => acc += item.kosten*item.anzahl, 0).toFixed(2)); 
      }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.btn-green-hs {
  background-color: #78B833;
  color:white;
}
</style>
