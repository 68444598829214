<template>
      <mdb-row class="mb-3">
        <mdb-col col="sm-12">
            <p class="h5 text-left mt-2">  Gericht {{index + 1}} - {{anzahl = anzahlGericht}} - {{kosten = kostenGericht}} - {{parseFloat(gesamtGericht)}} € </p>
        </mdb-col>  

         <mdb-col  md="1" sm="6" class="d-none d-md-block">
             <mdb-icon  @click.native="onDelete" icon="minus-circle" size="2x" color="danger" class="mt-4" />
        </mdb-col> 

         <mdb-col  md="2" sm="12">
        <mdb-select v-model="anzahlListe" placeholder=" " label="Portionen" @change="onChangeAnzahl($event)" />
        </mdb-col>

        <mdb-col md="7" sm="12" >
         <mdb-select search v-model="gerichtListe"  color="green" label="Gericht" placeholder="Wähle ein Gericht" @change="onChangeKosten($event)" />
        </mdb-col>

         <mdb-col  md="1" sm="6" class="d-block d-md-none  border-bottom border-dark">
             <mdb-icon  @click.native="onDelete" icon="minus-circle" size="2x" color="danger" class="mb-4" />
        </mdb-col> 

        <mdb-col md="2" sm="2" class="d-none d-md-block" >
            <p class="h4 text-left mt-2"> {{parseFloat(gesamtGericht)}} € </p>
        </mdb-col>


      </mdb-row>

</template>

<script>


import {mdbRow, mdbCol, mdbIcon, mdbSelect} from 'mdbvue';

export default {
  name: 'GerichtAuswahl',
  props: {
    index: {
      type: Number
    },
    anzahlGericht: {
        type: Number
    },
    kostenGericht: {
        type: Number
    }
  }, 
  data: () => ({
    anzahl: 1.0,
    kosten: 0.0,
    anzahlListe: [
          { text: '25', value: 25.00 , selected: true},
          { text: '24', value: 24.00 },
          { text: '23', value: 23.00 },
          { text: '22', value: 22.00 },
          { text: '21', value: 21.00 },
          { text: '20', value: 20.00 },
          { text: '19', value: 19.00 },
          { text: '18', value: 18.00 },
          { text: '17', value: 17.00 },
          { text: '16', value: 16.00 },
          { text: '15', value: 15.00 },
          { text: '14', value: 14.00 },
          { text: '13', value: 13.00 },
          { text: '12', value: 12.00 },
          { text: '11', value: 11.00 },
          { text: '10', value: 10.00 },
          { text: '9', value: 9.00 },
          { text: '8', value: 8.00 },
          { text: '7', value: 7.00 },
          { text: '6', value: 6.00 },
          { text: '5', value: 5.00 },
          { text: '4', value: 4.00 },
          { text: '3,', value: 3.00 },
          { text: '2', value: 2.00 },
          { text: '1,', value: 1.00 }
        ],
    gerichtListe: [
            {text: 'Gulaschsuppe - 3,90 €', value: '1-3.9'},
            {text: 'Leberknödelsuppe - 2,90 €', value: '2-2.9'},
            {text: 'Cremesuppe der Saison - 2,90 €', value: '3-2.9'},
            {text: 'saftiger Halsbraten mit Soße - 4,50 €', value: '4-4.5'},
            {text: 'Schäuferle gebraten mit krosser Schwarte und Soße - 4,90 €', value: '5-4.9'},
            {text: 'kleines Buffetschäuferle gebraten mit krosser Schwarte und Soße - 3,90 €', value: '6-3.9'},
            {text: 'fränkischer Spießbraten mit Soße (Schweinehals gefüllt mit Senf, Schinken und Röstzwiebel) - 4,80 €', value: '7-4.8'},
            {text: 'Cordonbleubraten mit Soße (Schweinehals gefüllt mit Schinken und Käse) - 4,80 €', value: '8-4,8'},
            {text: 'mediteraner Florentinerbraten mit Soße (Schweinerücken gefüllt mit Tomate, Spinat und Mozzarella) - 4,80 €', value: '9-4.8'},
            {text: 'Sauerbraten vom Rind mit Soße - 5,30 €', value: '10-5.3'},
            {text: 'zarter Rinderbraten mit Soße - 5,30 €', value: '11-5.3'},
            {text: 'Kalbsbraten mit Soße - 5,90 €', value: '12-5.9'},
            {text: 'Schweinelende mit Champignonrahmsoße - 5,00 €', value: '13-5'},
            {text: 'gebratene Spare Ribs - 4,80 €', value: '14-4.8'},
            {text: 'Kassler im Blätterteig - 4,80 €', value: '15-4.8'},
            {text: 'Backschinken - 4,50 €', value: '16-4.5'},
            {text: 'gebackene Buffetschnitzel paniert (120g) - 2,70 €', value: '17-2.7'},
            {text: 'gebackene Schnitzel paniert (200g) - 3,80 €', value: '18-3.8'},
            {text: 'gebackene Cordonbleu - 4,50 €', value: '19-4.5'},
            {text: 'Putenschnitzel paniert oder natur - 4,00 €', value: '20-4'},
            {text: 'Putenschnitzel klein paniert oder natur - 2,90 €', value: '21-2.9'},
            {text: 'Ofenrahmschnitzel mit Paprika, Zwiebeln oder Champignon - 4,50 €', value: '22-4.5'},
            {text: 'gebratene Bratwürste - 1,35 €', value: '23-1.35'},
            {text: 'sauere Bratwürste mit viel Zwiebeln - 1,60 €', value: '24-1.6'},
            {text: 'Currywurst mit deftiger Zwiebel-, Paprikasoße - 3,00 €', value: '25-3'},
            {text: 'Leberkäse - 3,30 €', value: '26-3.3'},
            {text: 'Schaschliktopf (Schweinefleisch, ger. Bündle, Paprika, Zwiebel und Schaschliksoße) - 4,00 €', value: '27-4'},
            {text: 'Gyrostopf (Schweinefleisch, Paprika, Sahne, Zwiebel und Knoblauch) - 4,00 €', value: '28-4'},
            {text: 'Rahmgeschnetzeltes mit Champignon oder Paprika - 4,20 €', value: '29-4.2'},
            {text: 'Putenrahmgeschnetzeltes mit Champignon oder Paprika - 4,50 €', value: '30-4.5'},
            {text: 'Lasagne - 4,60 €', value: '31-4.6'},
            {text: 'Chili con Carne - 4,00 €', value: '32-4'},
            {text: 'Spanferkel mit Soße portionsgerecht zerlegt - 6,50 €', value: '33-6.5'},
            {text: 'Hähnchenschenkel gebraten - 2,50 €', value: '34-2.5'},
            {text: 'Fischfilet mit Senf-Dillsoße - 4,90 €', value: '35-4.9'},
            {text: 'Käsespätzle - 3,50 €', value: '36-3.5'},
            {text: 'mediterane Gemüsepfanne - 4,20 €', value: '37-4.2'},
            {text: 'Reispfanne mit Gemüse - 4,20 €', value: '38-4.2'},
            {text: 'Gemüsevariation mit Kräuterbéchamelsoße - 2,50 €', value: '39-2.5'},
            {text: 'Gemüselasagne mit Käse überbacken - 3,90 €', value: '40-3.9'},
            {text: 'kl. Kloß 125g - 1,10 €', value: '41-1.1'},
            {text: 'Kloß 200g - 1,35 €', value: '42-1.35'},
            {text: 'Semmelkloß - 1,45 €', value: '43-1.45'},
            {text: 'kl. Semmelkloß - 1,20 €', value: '44-1.2'},
            {text: 'Spätzle - 1,35 €', value: '45-1.35'},
            {text: 'Kroketten - 1,35 €', value: '46-1.35'},
            {text: 'Kartoffelrösti - 1,35 €', value: '47-1.35'},
            {text: 'Reis - 1,35 €', value: '48-1.35'},
            {text: 'Nudeln - 1,35 €', value: '49-1.35'},
            {text: 'gek. Sauerkraut - 1,35 €', value: '50-1.35'},
            {text: 'Blaukraut - 1,35 €', value: '51-1.35'},
            {text: 'Wedges - 1,35 €', value: '52-1.35'},
            {text: 'Gurkensalat - 1,30 €', value: '53-1.3'},
            {text: 'Karottensalat - 1,30 €', value: '54-1.3'},
            {text: 'Krautsalat - 1,30 €', value: '55-1.3'},
            {text: 'bunte Salatvariation (gem. Blattsalat mit hausgemachtem Dressing, Karotte, Gurke und Kraut je separat angemacht) - 2,30 €', value: '56-2.3'},
            {text: 'gemischter Salat (gem. Blattsalat, Karotte, Gurke, Mais) fertig angemacht - 1,80 €', value: '57-1.8'},
            {text: 'Blattsalat mit hausgemachtem Dressing - 1,50 €', value: '58-1.5'},
            {text: 'Mousse au Chocolat - 2,60 €', value: '59-2.6'},
            {text: 'Bayrisch Creme mit Pfirsichmousse - 2,60 €', value: '60-2.6'},
            {text: 'Joghurt Creme mit roter Grütze - 2,60 €', value: '61-2.6'},
            {text: 'Obstsalat - 2,80 €', value: '62-2.8'}
        ]
  }),
  methods: {
    onDelete () {
        this.$emit('delete', this.index) 
    },
    onChangeAnzahl(e){
         this.$emit('changedAnzahl', this.index, e )
    },
    onChangeKosten(e){
        e = e.split('-')
         this.$emit('changedKosten', this.index, e[1] )
    }
  },
  components: {
    mdbRow,
    mdbCol,
    mdbIcon,
    mdbSelect
  },
  computed: {
      gesamtGericht(){
          return (parseFloat(this.anzahl) * parseFloat(this.kosten)).toFixed(2);
      }
  }
}

</script>

<style scoped>
    .delBtn {
        margin-top: 0px;
    }
    .delIcon {
        margin: 0px;
        padding: 0px;
    }
</style>


